var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "vehicleBinding", staticClass: "vehicle-binding" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "functionButtons",
          staticClass: "function-buttons"
        },
        [_c("el-button", { attrs: { type: "primary", size: "small" } })],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: { model: _vm.form, "label-position": "left", inline: true }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c("car-tree", {
                    ref: "carTree",
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.search }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight,
            width: "100%"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", width: "70" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "locationName",
              label: "区域名称",
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }